<template>
    <!-- Main -->
    <div class="container mt-3" v-if="!loading && program.documentProgram != undefined">
        <Button class="p-button-raised p-button-rounded my-3 p-button-danger" icon="pi pi-arrow-left" @click="goBack" />
        <header v-if="selectedTopic">
            <div style="display: flex; align-items: center; margin-bottom: 1.5rem;"  >
              <h3 style="margin-bottom: 0;" >EDIT PROGRAMME - {{ selectedTopic.name }}</h3>
              <Button 
                class="p-button-raised p-button-rounded my-3 p-button-help mx-3" 
                icon="pi pi-question-circle" 
                @click="goToHeaderPage('programEdit-header')" >
              </Button>
            </div>

            <Divider align="left">
              <div class="inline-flex align-items-center">
                <span class="p-tag">Information</span>
                <Button 
                  class="p-button-raised p-button-rounded p-button-help mx-3" 
                  icon="pi pi-question-circle" 
                  @click="goToHeaderPage('programEdit-section1')" />
              </div>
            </Divider>
            <!-- Programmes analyst status -->
            <div 
                style="display: flex;"
            >
                <div
                    style="width: 75%;"
                >

                    <div style="
                        display: flex; 
                        flex-direction: column; 
                        margin-left: 20px; 
                        margin-bottom: 20px; 
                        margin-top: 10px;
                    ">
                        <label for="ProgramName">Programme</label>
                        <InputText id="name" v-model.trim="newForm.name" required="true" autofocus
                            :class="{ 'p-invalid': !newForm.name }" />
                        <small class="p-error" v-if="!newForm.name">
                            Name is required
                        </small>
                    </div>
                </div>

                <div
                    style="width: 25%;"
                >

                    <div style="
                        margin-left: 20px; 
                        display: flex; 
                        gap: 2%; 
                        margin-top: 1.5%; 
                        margin-left: 20px; 
                        margin-bottom: 1rem;
                    ">
                        <div style="width:100%">
                            <div class="CTU">
                                <label>Analyst Status - Internal Use only</label>
                                <!-- <InputText v-model.trim="newForm.product" /> -->
                                <Dropdown 
                                    v-model="newForm.documentProgramStatusId" 
                                    :options="programStatus" 
                                    option-label="status"
                                    optionValue="documentProgramStatusId" 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- segmentations -->
            <div 
                v-if="segmentations" 
                style="
                    display: flex;  
                    align-items: center; 
                    gap: 1rem; 
                    margin-left: 20px;
                ">
                <label>Segments</label>
                <div v-for="(segmentation, index ) in segmentations" :key="segmentation.segmentationId">
                    <div v-if="!segmentation.isRegional" style="min-width: 10rem;">
                        <Divider align="left">
                            <span class="p-tag">{{ segmentation.name }}</span>
                        </Divider>
                        <Dropdown 
                            v-model="newForm.segmentationItems[index]"
                            :options="getSegmentationsOptions(segmentation.segmentationItems)" 
                            optionLabel="name"
                            optionValue="segmentationItemId" 
                            placeholder="Select SegmentationItems " 
                            class="my-1" 
                        />
                    </div>
                    <div v-else style="min-width: 10rem;">
                        <Divider align="left">
                            <span class="p-tag">{{ segmentation.name }}</span>
                        </Divider>
                        <Dropdown 
                            v-model="country[index]" 
                            :options="getCountriesFromSegm(index)" 
                            optionLabel="name"
                            optionValue="countryId" 
                            :filter="true" 
                            placeholder="Select Country"
                            @change="setSegmItem(index, $event)" 
                            class="my-1" 
                        />
                    </div>
                </div>
            </div>
            <!-- Header information -->
            <div style="
                    margin-left: 20px; 
                    display: flex; 
                    gap: 2%; 
                    margin-top: 1.5%;
                    margin-bottom: 1.4rem;
                ">
                <div style="width:25%">
                    <div class="PMC">
                        <label for="Product">Product</label>
                        <InputText v-model.trim="newForm.product" />
                    </div>
                    <div class="PMC">
                        <label for="Manufacturer">Manufacturer</label>
                        <!-- <InputText v-model.trim="newForm.manufacturer" /> -->
                        <Dropdown 
                            v-model="newForm.manufacturerId"
                            :options="companies"
                            optionLabel="name"
                            optionValue="companyId"
                            :filter="true"
                            placeholder="Select Manufacturer"
                        />

                    </div>
                    <div class="PMC">
                        <label for="CountryManufacturer">Country Manufacturer</label>
                        <!-- <InputText v-model.trim="newForm.countryManufactured" /> -->
                        <!-- Database Is Saves As string  -->
                        <Dropdown 
                            v-model="newForm.countryManufactured"
                            :options="countries"
                            optionLabel="name"
                            optionValue="name"
                            :filter="true"
                            placeholder="Select Country Manufacturer"
                        />
                    </div>
                </div>
                <div style="width:25%">
                    <div class="CTU">
                        <label for="ContractYear">Programme Year(s)</label>
                        <div class="CTU-contractYear">
                            <div style="position: relative; width: 50%; display: flex; justify-content: center; align-items: center;">
                                <Calendar v-model="newForm.programStartYear" view="year" dateFormat="yy" class="CTU-contractYear-input" />
                                <i class="pi pi-angle-down CTU-contractYear-input-icon" style="font-size: 1rem"></i>
                            </div>
                            <div style="position: relative; width: 50%; display: flex; justify-content: center; align-items: center;">
                                <Calendar v-model="newForm.programEndYear" view="year" dateFormat="yy" class="CTU-contractYear-input" />
                                <i class="pi pi-angle-down CTU-contractYear-input-icon" style="font-size: 1rem"></i>
                            </div>

                        </div>
                    </div>
                    <div class="CTU">
                        <label for="TotalValue">Total Value</label>
                        <InputText v-model.trim="newForm.contractValue" />
                    </div>
                    <div class="CTU">
                        <label for="UnitPrice">Unit Price</label>
                        <InputText v-model.trim="newForm.unitPrice" />
                    </div>
                </div>
                <div style="width:25%">
                    <div class="TqD">
                        <label for="TotalQuantity">Total Quantity</label>
                        <InputText v-model.trim="newForm.totalQuantity" />
                    </div>
                    <div class="TqD">
                        <label for="Delivery">Delivery Year</label>
                        <InputText v-model.trim="newForm.deliveryYear" />
                    </div>
                    <div class="TqD">
                        <label for="Delivery">Programme update date </label>
                        <!-- <p>{{formatDate(newForm.programDate)}}</p> -->
                        <Calendar v-model="newForm.programDate" dateFormat="dd/mm/yy" disabled />
                    </div>

                </div>
                <div style="width:25%; ">
                </div>
            </div>

            <!-- Program Summary , Analyst Notes -->
            <div style="
                display: flex;
                gap: 2%;
                margin-top: 1.5%;
                margin-left: 20px;
                margin-bottom: 1.4rem;
            ">
                <div style="width:50%;">
                        <div style="display:flex; flex-direction:column; ">
                            <label for="notes" style="margin-bottom: 1rem">Programme Summary for "Programmes per Country Overview"</label>
                            <!-- <QuillEditor 
                                theme="snow" 
                                v-model:content="newForm.programSummary" 
                                :toolbar="quillToolbar"
                                contentType="html"
                                :style="{ 'min-height': '200px' }" 
                            /> -->

                            <QuillEditor 
                                theme="snow" 
                                v-model:content="newForm.programSummary" 
                                :toolbar="quillToolbar"
                                contentType="html"
                                :style="{ 'min-height': '120px',}" 
                            />

                            <!-- <Textarea 
                                rows="5" 
                                cols="30" 
                                v-model.trim="newForm.programSummary"
                                :value="newForm.programSummary" 
                            /> -->
                        </div>
                </div>

                <div style="width:50%;">
                        <div style="display:flex; flex-direction:column; gap:1rem;">
                            <label for="notes">Analyst Notes</label>
                            <!-- <Textarea 
                                rows="13" 
                                cols="30" 
                                v-model.trim="newForm.analystNotes"
                                :value="newForm.analystNotes" 
                            /> -->

                            <Textarea 
                                rows="8" 
                                cols="30" 
                                v-model.trim="newForm.analystNotes"
                                :value="newForm.analystNotes" 
                            />

                            
                        </div>
                </div>
            </div>

        </header>

        <!-- Document DataTable -->
        <DataTable 
            class=" mt-3 mx-4" 
            :value="documents" 
            v-model:expandedRows="expandedRows" 
            @rowExpand="onRowExpand"
            @rowCollapse="onRowCollapse" 
            :loading="loadingDocuments" 
            responsiveLayout="scroll" 
            showGridlines
            dataKey="documentId" 
            stripedRows>
            <template #header>
                <div 
                    style="
                        display:flex; 
                        justify-content: space-between; 
                        align-items: center;
                    ">
                    <div style="display: flex; align-items: center;">
                        <h5 style="margin-bottom: 0;">Documents</h5>
                        <Button
                            class="p-button-raised p-button-rounded my-3 p-button-help mx-3 " 
                            icon="pi pi-question-circle" 
                            @click="goToHeaderPage('programEdit-section2')"
                        />
                    </div>

                    <div
                        style="display:flex; align-items: center; gap: 0.5rem"
                    >
                        <Button 
                            type="button" 
                            icon="pi pi-plus" 
                            label="Link existing Documents" 
                            class="p-button-outlined"
                            @click="this.linkDocumentDialog = true" 
                        />
                        
                        <Button 
                            type="button" 
                            icon="pi pi-plus" 
                            label="Add new Document" 
                            class="p-button-outlined"
                            @click="openAddDocumentPrevStep()" 
                        />
                    </div>
                    
                </div>
            </template>
            <ColumnGroup type="header">
                <Row>
                    <Column 
                        style="min-width: 40rem; " 
                        :field="'title'" 
                        :header="'Title'" 
                        :key="'title'" 
                        :sortable="true"
                    >
                    </Column>
                    <Column :field="'dateAdded'" :header="'Date Added'" :key="'dateAdded'" bodyStyle="text-align: center"
                        :sortable="true"></Column>
                    <Column :field="'documentDate'" :header="'Document Date'" :key="'documentDate'"
                        bodyStyle="text-align: center" :sortable="true"></Column>
                    <!-- <Column
                        :field="'applications'"
                        :header="'Applications'"
                        :key="'applications'"
                    >
                    </Column> -->
                    <!-- <Column 
                        :field="'sourceId'" 
                        :header="'Source'" 
                        :key="'sourceId'" 
                    >
                    </Column> -->
                    <Column :field="'sourceName'" :header="'Source Name'" :key="'sourceId'" :sortable="true">
                    </Column>
                    <!-- PreviewButton -->
                    <Column>
                    </Column>
                    <Column headerStyle="width: 3rem">
                        <template #header>
                            <Button 
                                @click="expandAll" 
                                class="p-button-success p-button-rounded ms-3" 
                                style="display: flex; justify-content: center; align-items: center;"  
                                v-if="expandedRows === null || expandedRows.length === 0"  
                            >
                                <font-awesome-icon icon="fa-regular fa-maximize" />
                            </Button>
                        
                            <Button 
                                @click="collapseAll"
                                class="p-button-success p-button-rounded ms-3" 
                                style="display: flex; justify-content: center; align-items: center;"    
                                v-else
                            >
                                <font-awesome-icon icon="fa-regular fa-minimize" />
                            </Button>
                        </template>
                    </Column>
                </Row>
            </ColumnGroup>
            <!-- title -->
            <Column style="min-width: 40rem; " :field="'title'" :header="'Title'" :key="'title'" :sortable="true">
                <template #body="slotProps">

                    <div @click="openEditDoc(slotProps.data.documentId)" :style="{ cursor: 'pointer' }">
                        {{ slotProps.data.title }}
                    </div>
                </template>
            </Column>
            <!-- Document DateAdded -->
            <Column :field="'dateAdded'" :header="'Date Added'" :key="'dateAdded'" :sortable="true"
                bodyStyle="text-align: center">
                <template #body="slotProps">
                    {{ formatDate(slotProps.data.dateAdded) }}
                </template>
            </Column>
            <!-- Document Date -->
            <Column :field="'documentDate'" :header="'Document Date'" :key="'documentDate'" :sortable="true"
                bodyStyle="text-align: center">
                <template #body="slotProps">
                    {{ formatDate(slotProps.data.documentDate) }}
                </template>
            </Column>
            <!-- application -->
            <!-- <Column 
                :field="'applications'" 
                :header="'Applications'" 
                :key="'applications'" 
                :sortable="false"
            >
                <template #body="slotProps">
                <div>
                    <span 
                    v-tooltip="application.name" 
                    class="mx-2" 
                    v-for="application of getApplicationNameById(
                    slotProps.data.applications
                    )" 
                    :key="application.applicationTypeId"
                    >
                    <span v-html="application.icon"></span>
                    <font-awesome-icon :icon="[application.prefix, application.iconName]" />            
                    </span>
                </div>
                </template>
            </Column> -->
            <!-- sourceId -->
            <!-- <Column 
                :field="'sourceId'" 
                :header="'Source'" 
                :key="'sourceId'" 
                :sortable="true"
            >
                <template #body="slotProps">
                {{ getSourceNameById(slotProps.data.sourceId) }}
                </template>
            </Column> -->
            <Column :field="'sourceName'" :header="'Source Name'" :key="'sourceId'">
                <template #body="slotProps">
                    {{ slotProps.data.sourceName }}
                </template>
            </Column>
            <!-- Preview Buttons -->
            <Column :exportable="false" style="min-width: 5rem">
                <template #body="slotProps">
                    <div style="display: flex; ">
                        <div>
                            <div v-if="slotProps.data.text">
                                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mx-1"
                                    @click="openPreviewDocumentDialog(slotProps.data)"
                                    @mouseenter="hoverPreviewDocumentDialogIn(slotProps.data)"
                                    @mouseleave="hoverPreviewDocumentDialogOut" />
                            </div>
                            <div v-else>
                                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mx-1" :disabled="true" />
                            </div>
                        </div>

                        <div>
                            <div v-if="slotProps.data.contract">
                                <Button 
                                    icon="pi pi-file" 
                                    class="p-button-rounded p-button-success mx-1"
                                    @click="openPreviewDocumentContracDialig(slotProps.data)"
                                    @mouseenter="hoverPreviewDocumentContractDialogIn(slotProps.data)"
                                    @mouseleave="hoverPreviewDocumentContractDialogOut"
                                >
                                        <font-awesome-icon icon="fa-solid fa-file-contract" />
                                </Button>
                            </div>
                            <div v-else>
                                <Button icon="pi pi-file" class="p-button-rounded p-button-success mx-1" :disabled="true">
                                    <font-awesome-icon icon="fa-solid fa-file-contract" />
                                </Button>
                            </div>
                        </div>

                        <div>
                            <div v-if="slotProps.data.filePublicUrl">
                                <Button 
                                    icon="pi pi-file-pdf" 
                                    class="p-button-rounded p-button-success mx-1"
                                    @click="openPreviewDocumentFileDialig(slotProps.data)">
                                </Button>
                            </div>
                            <div v-else>
                                <Button 
                                    icon="pi pi-file-pdf" 
                                    class="p-button-rounded p-button-success mx-1"
                                    :disabled="true"
                                >
                                </Button>
                            </div>
                        </div>

                        <div>
                            <div >
                                <Button
                                    icon="pi pi-trash"
                                    class="p-button-rounded p-button-danger mx-1"
                                    @click="openUnlinkDocumentDialog(slotProps.data)"
                                >
                                    <font-awesome-icon icon="fa-solid fa-link-slash" />
                                </Button>
                            </div>
                        </div>

                    </div>
                </template>
            </Column>

            <Column :expander="true" headerStyle="width: 3rem" />
            <template #expansion="slotProps">
                <div>
                    <Carousel 
                        :programStartYear="newForm.programStartYear" 
                        :programEndYear="newForm.programEndYear"
                        :document="true" 
                        :p="position" 
                        :documentMarketValues="getFilteredTopicMarket(slotProps.data)"
                        :sumMarket="sumMarket" 
                    />
                </div>
            </template>
        </DataTable>

        <div style="position:relative;">

            <!-- Summary Carousel -->
            <div style="position: relative; margin: 2% 0;">
                <Divider align="left">
                    <div class="inline-flex align-items-center">
                        <span class="p-tag">Document Summary - STATED VALUES</span>
                        <Button 
                        class="p-button-raised p-button-rounded p-button-help mx-3" 
                        icon="pi pi-question-circle" 
                        @click="goToHeaderPage('programEdit-section3')" />
                    </div>
                </Divider>

                <div style="
                        width: 10%; 
                        position: absolute; 
                        top: 80%; 
                        left:2%; 
                        display: flex; 
                        flex-direction: column;
                    "
                >
                    <label>Programme</label>
                    <label>Values</label>
                </div>
                <!-- Value units Icons -->
                <div
                    style="
                        width: 10%; 
                        position: absolute; 
                        padding-top: 0.2%; 
                        top: 80%; 
                        left:8.5%; 
                        display: flex; 
                        flex-direction: column; 
                        gap: 0.7rem;
                    "
                >
                    <label><strong>$</strong></label>
                    <label><strong>#</strong></label>
                </div>

                <!-- program Values -->
                <!-- <div v-if="minValue && sumMarket " -->
                <div v-if="!loadingCarousel"
                    style="
                        display: flex; 
                        align-items: center; 
                        margin-top: 0.5%; 
                        margin-left: 10%;
                ">
                    <Carousel
                        :programStartYear="(newForm.programStartYear)?newForm.programStartYear.getFullYear() : null" 
                        :programEndYear="(newForm.programEndYear)?newForm.programEndYear.getFullYear() :null"
                        :summary="true" 
                        :minValue="minValue" 
                        :maxValue="maxValue" 
                        :p="position" 
                        :summaryMarketValues="sumMarket"
                        @sumP="sumP" 
                        @subP="subP" 
                        @getSummaryMarketValue="getSummaryMarketValue" 
                        @addMaxValue="addMaxValue"
                        @subMinValue="subMinValue"
                    />
                </div>
            </div>

            <!-- Copy Program Values to Base Scenario -->
            <div style="margin: 5% 0 ;">
                <div style=" margin-top: 0.5%; margin-left: 7%;" >
                    <Button
                            class="p-button-success " 
                            :loading="postSumProgramIsLoading"
                            @click="openCopyProgramValuesToBaseScenarioModal()"
                        >
                        <!-- @click="copyProgramValuesToBaseScenario()"  -->
                            <label style="margin-right: 0.5rem;">Copy programme values to Base Scenario</label>
                    </Button>

                </div>
            </div>

            <!-- scenarios Carousel -->
            <Divider align="left">
                <div class="inline-flex align-items-center">
                    <span class="p-tag">Scenarios - STATED & DERIVED VALUES</span>
                    <Button 
                        class="p-button-raised p-button-rounded p-button-help mx-3" 
                        icon="pi pi-question-circle" 
                        @click="goToHeaderPage('programEdit-section4')" 
                    />
                </div>

            </Divider>
            <div v-for="(scenario, index) in scenarios" :key="scenario.scenarioId">
                <div class=" mt-3" v-if="minValue" style="display: flex; align-items: center; margin-left: 20px;">
                    
                    <label v-if="index != 0" style="padding-top: 0.5%; width: 7%;"> {{ scenario.name }} </label>
                    <label v-else style="padding-top: 0.5%; width: 7%;">   Base Scenario </label>

                    <div v-if="index != 0"
                        style="width:2.5%;padding-top: 0.8%; display: flex; flex-direction: column; justify-content: center; align-items: center; gap:0.7rem">
                        <label><strong>%</strong></label>
                        <label><strong>$</strong></label>
                        <label><strong>#</strong></label>
                    </div>
                    <div v-else
                        style="width:2.5%;padding-top: 0.8%; display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 0.7rem;">
                        <label><strong>$</strong></label>
                        <label><strong>#</strong></label>
                    </div>
                    <div>
                    </div>
                    <div v-if="index != 0">
                        <Carousel 
                            :programStartYear="(newForm.programStartYear)?newForm.programStartYear.getFullYear() : null" 
                            :programEndYear="(newForm.programEndYear)?newForm.programEndYear.getFullYear() :null"
                            :scenario="true" 
                            :p="position" 
                            :minValue="minValue" 
                            :maxValue="maxValue" 
                            :sumMarket="sumMarket"
                            :scenarioIndex="index" 
                            :scenarioMarketValues="getCorrectScenario(scenario.scenarioId)"
                            :baseScenario="scenarioMarket[0]" 
                            @getScenarioMarketValue="getScenarioMarketValue" 
                        />
                    </div>
                    <div v-else>
                        <Carousel 
                            :programStartYear="(newForm.programStartYear)?newForm.programStartYear.getFullYear() : null" 
                            :programEndYear="(newForm.programEndYear)?newForm.programEndYear.getFullYear():null"
                            :scenario="true" 
                            :p="position" 
                            :minValue="minValue" 
                            :maxValue="maxValue"
                            :sumMarket="sumMarket"
                            :scenarioIndex="index" 
                            :scenarioMarketValues="getCorrectScenario(scenario.scenarioId)"
                            @getScenarioMarketValue="getScenarioMarketValue" 
                            :isCopyToBaseScenario="isCopyToBaseScenario"
                            @changeIsCopyToBaseScenario="changeIsCopyToBaseScenario"
                            :baseScenarioMarketCopy="baseScenarioMarketCopy"
                        />
                    </div>
                </div>
                <!-- <div style="display:flex; flex-direction:column; margin-top: 1.5%;">
                    <h5>Notes</h5>
                    <Textarea rows="5" cols="30" />
                </div> -->
            </div>

            <!-- Sum Margin -->
            <div style="
                position:absolute; 
                right: 0.5%; 
                top: 3rem;
                width: 8.5%;
                height: calc(100% - 3rem);
                border-radius: 4px;
                border: solid 1px gray;
            ">
            </div>

        </div>

        <!-- Save Button -->
        <div class="row" style="margin-top: 1rem;">
            <div class="col-12 p-4">
                <div class="d-flex justify-content-end px-3" style="gap: 1rem;">
                    <!-- <Button 
                        label="Save" 
                        icon="pi pi-check" 
                        class="p-button-success ml-3" 
                        :loading="postSumProgramIsLoading"
                        @click="postSumProgram()" 
                    /> -->

                    <Button
                        class="p-button"
                        :loading="postSumProgramIsLoading"
                        @click="saveProgram()" 
                    >
                        <label style="margin-right: 0.5rem;" >Save</label>
                        <font-awesome-icon icon="fa-regular fa-floppy-disk" />
                    </Button>

                    <Button
                        class="p-button-success " 
                        :loading="postSumProgramIsLoading"
                        @click="saveProgramGoBack()" 
                    >
                        <label style="margin-right: 0.5rem;">Save and Go Back</label>
                        <font-awesome-icon icon="fa-regular fa-floppy-disk-circle-arrow-right" />
                    </Button>
                </div>
            </div>
        </div>
    </div>

    <!-- MODALS, LOADING  -->
    <!-- loading -->
    <div v-else>
        <div class="row">
            <div class="col-12 py-4 d-flex justify-content-center">
                <ProgressSpinner />
            </div>
        </div>
    </div>


    <!-- Link Document -->
    <Dialog
        v-model:visible="this.linkDocumentDialog"
        :style= "{width: '80%'}"
        header="Link Documents"
        :modal="true"
    >
        <div v-if="documentsByTopicIdForLinkDocumentsToProgram">
            <DocumentsFilteredDataTable
                :companies="companies"
                :documentsFilteredByCountries="changeDocumentsFilteredByCountries()"
                :countryId="country[0]"
                :countriesOptions="(showAllDocumentsForLinkDocumentsProgram)?countriesOptionsForLinkDocumentAll:countriesOptionsForLinkDocumentByTopicId"
                :showAllDocumentsForLinkDocumentsProgram="showAllDocumentsForLinkDocumentsProgram"
                :documentSources="documentSources"
                @setDocumentsForLinkProgram="setDocumentsForLinkProgram"
                @changeShowAllDocuments="changeShowAllDocuments"
            />
        </div>

        <template #footer >
            <div style="display: flex; justify-content: space-between; align-items: center ;">
                <span class="p-error" v-if="linkDocumentError">
                    {{ linkDocumentError }}
                </span>
                <span v-else></span>
                <div style="display:flex; gap:1rem">
                    <Button
                        label="Cancel"
                        icon="pi pi-times"
                        class="p-button-text"
                        @click="closeDialog()"
                    />

                    <div v-if="this.documentsLinksProgram.length === 0 || !this.documentsLinksProgram ">
                        <Button
                            class="p-button-info"
                            disabled
                        >
                            <label style="margin-right: 0.5rem;" >Link Document</label>
                        </Button>
                    </div>
                    <div v-else>
                        <Button
                            class="p-button-info"
                            @click="linkDocuments"
                            :loading="linkDocumentsLoading"
                        >
                            <label style="margin-right: 0.5rem;" >Link Document</label>
                        </Button>
                    </div>
                    
                </div>
            </div>
        </template>
    </Dialog>

    <!-- unLink Document -->
    <Dialog
        :style="{ width: '450px' }"
        v-model:visible="this.unlinkDocumentDialog"
        header="Unlink Document"
        :modal="true"
     >
        <div class="confirmation-content d-flex flex-row">
            <i class="pi pi-exclamation-triangle me-3" style="font-size: 2rem" />
            <span
                >Are you sure you want to unlink <strong>{{unLinkDocumentForm.title}}</strong> 
            </span>
        </div>

        <template #footer >
            <div style="display: flex; justify-content: space-between; align-items: center ;">
                <span class="p-error" v-if="unLinkDocumentError">
                    {{ unLinkDocumentError }}
                </span>
                <span v-else></span>
                <div style="display:flex; gap:1rem">
                    <Button
                        label="No"
                        icon="pi pi-times"
                        class="p-button-text"
                        @click="closeDialog()"
                    />

                    <Button
                        label="Yes"
                        icon="pi pi-check"
                        class="p-button-text"
                        @click="unLinkDocuments"
                        :loading="unLinkDocumentsLoading"
                    >
                    </Button>
                </div>
            </div>
        </template>
     </Dialog>

    <!-- add Document -->
    <Dialog
        v-model:visible="addDocumentPrevStep"
        :style="{ width: '45%' }"
        header="Add new document to Programme"
        :modal="true"
    >
        <div>
            Save Program before proceeding to document information.
        </div>


        <template #footer>
            <Button 
                label="Cancel" 
                icon="pi pi-times" 
                class="p-button-text" 
                @click="closeDialog()" 
            />

            <Button
                label="Redirect Without Save"
                class="p-button-info"
                @click="openAddDocument()"
            >
            </Button>

            <Button 
                label="Save & Redirect" 
                class="p-button-success" 
                @click="redirectSaveDocument()" 
            />
        </template>
    </Dialog> 


    <Dialog
        v-model:visible="addDocument"
        :style="{ width: '80%' }"
        header="Website url"
        :modal="true"
    >
        <div style="display: flex; flex-direction: column; gap: 1rem;">
            <DocumentCrawlerVue
                @getDocumentDataApifyWebsiteContentCrawler="getDocumentDataApifyWebsiteContentCrawler"
                @getDocumentDataApifySmartArticleExtractor="getDocumentDataApifySmartArticleExtractor" 
            />

            <div
                style="display: flex;
                justify-content:center;"
            >
                <Button 
                label="Add Document manually"
                class="p-button-text"
                @click="openCreateDocumentDialog"
                />
            </div>
        </div>
    </Dialog>


    <!-- Preview Document  -->
    <Dialog v-model:visible="previewDocumentDialog" :style="{ width: '80%' }" :modal="true">
        <template #header>
            <h5> Preview <strong>{{ selectedRowDocument.title }} </strong> </h5>
        </template>
        <div v-html="selectedRowDocument.text"></div>
    </Dialog>

    <!-- Preview Document Hover -->
    <div class="modalPreview" v-show="previewHoverDocumentDialog">
        <div class="modalContainer">
            <div v-if="selectedRowDocument">
                <h5>Preview <strong>{{ selectedRowDocument.title }} </strong> </h5>
                <div v-html="selectedRowDocument.text" class="modalContainer-Text"></div>
            </div>
            <div v-else>
                loading
            </div>
        </div>
    </div>

    <!-- Preview DocumentContract  -->
    <Dialog v-model:visible="previewDocumentContractDialog" :style="{ width: '80%' }" :modal="true">
        <template #header>
            <h5> Contract for: {{ selectedRowDocumentContract.title }}</h5>
        </template>
        <div>
            {{ selectedRowDocumentContract.contract }}
        </div>
    </Dialog>

    <!-- preview DocumentContract Hover  -->
    <div class="modalPreview" v-show="previewHoverDocumentContractDialog">
        <div class="modalContainer">
            <div v-if="selectedRowDocumentContract">
                <h5>Contract <strong>{{ selectedRowDocumentContract.title }} </strong> </h5>
                <div>
                    {{ selectedRowDocumentContract.contract }}
                </div>
            </div>
            <div v-else>
                loading
            </div>
        </div>
    </div>

    <!-- Preview DocumentFile -->
    <Dialog v-model:visible="previewDocumentFileDialog" :style="{ width: '80%' }" :modal="true">
        <template #header>
            <h5> File for {{ selectedRowDocumentFile.title }}</h5>
        </template>
        <div>
            <a :href="selectedRowDocumentFile.filePublicUrl" target="_blank" rel="Document File" class="document-File">
                <i class="pi pi-file-pdf" style="font-size: 2rem"></i>
                <label for="file">{{
                    selectedRowDocumentFile.filePublicUrl.split('/')[selectedRowDocumentFile.filePublicUrl.split('/').length
                    - 1] }}</label>
            </a>
        </div>
    </Dialog>

    <Dialog v-model:visible="showPreviewCopyMarketValues" :style="{ width: '40%' }" :modal="true">
        <template #header> 
            <h5> Copy Market Values </h5>
        </template>

        <div>
            <p> <strong>Add Button:</strong> Add market values without replacing scenario market values </p>
            <p> <strong>Replace Button:</strong> Replace market values to scenario market values </p>
        </div>

        <template #footer>
            <Button
                label="Cancel"
                icon="pi pi-times"
                class="p-button-text"
                @click="closePreviewCopyMarketValuesDialog()"
            />

            <Button
                class="p-button-info"
                :loading="postSumProgramIsLoading"
                @click="addProgramValuesToBaseScenario()"
            >
                <label style="margin-right: 0.5rem;" >Add</label>
                <!-- <font-awesome-icon icon="fa-regular fa-floppy-disk" /> -->
            </Button>

            <Button
                class="p-button-success " 
                :loading="postSumProgramIsLoading"
                @click="replaceProgramValuesToBaseScenario()"
            >
                <label style="margin-right: 0.5rem;">Replace</label>
                <!-- <font-awesome-icon icon="fa-regular fa-floppy-disk-circle-arrow-right" /> -->
            </Button>
        </template>
    </Dialog>
</template>

<script>
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'
import Row from 'primevue/row'
import formatToGlobalDate from '../../../../../../shared/helpers/date.helper';
import Textarea from 'primevue/textarea';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
import Carousel from '../components/Carousel.vue';
import { QuillEditor } from '@vueup/vue-quill';


import redirectHelperPage from '../../../../../../shared/helpers/redirectToHelperPage';

import { editProgramOverview , linkDocumentsToPrograms, unlinkDocumetToProgram } from '../../../../program/services/program.service'

// components
import DocumentCrawlerVue from '../../../../../../components/DocumentCrawler.vue';
import DocumentsFilteredDataTable from '../components/DocumentsFilteredDataTable.vue';

// 
import { createFormData } from '@/shared/helpers/formData.helper';
import { createDocument } from '../../../../document/services/document.service';

import { useToast } from 'vue-toastification';
const  toast = useToast();

export default {
    name: 'ProgramOverview',
    props: ['fromCreated'],
    components: {
        InputText,
        DataTable,
        Column,
        ColumnGroup,
        Row,
        Textarea,
        Divider,
        Dropdown,
        Button,
        Calendar,
        Carousel,
        ProgressSpinner,
        Dialog,
        DocumentCrawlerVue,
        DocumentsFilteredDataTable,
        QuillEditor,
    },
    data() {
        return {
            // DIALOGS,
            selectedRowDocument: null,
            selectedRowDocumentContract: null,
            selectedRowDocumentFile: null,

            previewDocumentDialog: false,
            previewHoverDocumentDialog: false,

            previewDocumentContractDialog: false,
            previewHoverDocumentContractDialog: false,

            previewDocumentFileDialog: false,

            // NEW FORM
            newForm: {
                name: null,
                segmentationItems: [],
                programSummary: null,
                product: null,
                // Replace Manufacturer to manufacturerId
                // manufacturer: null,
                manufacturerId: null,
                countryManufactured: null,
                //we are not Using ContractYear
                contractYear: null,
                programStartYear: null,
                programEndYear: null,

                contractValue: null,
                unitPrice: null,
                totalQuantity: null,
                deliveryYear: null,
                programDate: null,
                documentProgramStatusId: null,

                analystNotes: null,
            },

            // LOADINGS 
            postSumProgramIsLoading: false,
            loading: true,
            loadingDocuments: true,
            position: 1,
            minValue: null,
            maxValue: null,
            sumMarket: [],
            scenarioMarket: [],
            percentMarket: [],
            // sumMarketUnits: [], //for units
            country: [],
            region: [],

            docTopicSegItemsId: [],
            expandedRows: [], //for unfold carousel
            showExpandedButton: false, 
            uniqueOptions: [],

            addDocumentPrevStep: false,
            addDocument : false, 
            linkDocumentDialog: false,

            showPreviewCopyMarketValues:false,
            baseScenarioMarketCopy: [],
            isCopyToBaseScenario: false, //validation For CopyProgramValues and Update baseScenario

            // filteredDocument For Link Document
            // countriesFiltered: [],
            countriesFiltered: null,
            filteredDocument: {
                document: null
            },

            // links document to program
            loadingDocumentsForLink : true, // check where to use
            documentsLinksProgram: [], // variable for links documents to program 
            linkDocumentsLoading: false,
            linkDocumentError: null,
            showAllDocumentsForLinkDocumentsProgram: false,

            countriesOptionsForLinkDocumentByTopicId: null, 
            countriesOptionsForLinkDocumentAll: null,

            // unlink Document To Program 
            unlinkDocumentDialog: false,
            unLinkDocumentError: null,
            unLinkDocumentsLoading:false,
            unLinkDocumentForm: {
                documentId: null,
                title: null,
            },

            //loading carousel
            loadingCarousel:true,

            quillToolbar: [
                [{ 'header': [4,5, false] }],  // Adding heading
                ['bold', 'italic', 'underline', ], // textFormat
                [{ 'list': 'ordered' }, { 'list': 'bullet' }], // lists
            ],
            
        }
    },
    async mounted() {
        // get topic by store 2
        await this.getDocumentSources();
        await this.getDocumentApplications();
        await this.getProgramStatus(); //Get Program Status Stored in program 
        await this.getCompanies();
        await this.getCountries(); 
        
        await this.getProgramById() //get Program  
    },
    computed: {
        documentApplications() {
            const applications = this.$store.getters.obtainDocumentApplications;
            applications.forEach((application) => {
                if (
                    application.name == 'Production' ||
                    application.name == 'Requirements'
                ) {
                    this.uniqueOptions.push(application);
                }
            });
            return applications;
        },
        documentSources() {
            let documentSources = this.$store.getters.obtainDocumentSources;
            // documentSources.sort((a, b) => a.name.localeCompare(b.name));
            documentSources.sort((a, b) => a.documentSourceId - b.documentSourceId);
            return documentSources;
        },
        selectedTopic() {
            return this.$store.getters.obtainSelectedTopic;
        },
        segmentations() {
            return this.$store.getters.obtainSegmentations;
        },
        documents() {
            return this.$store.getters.obtainDocuments;
        },
        documentsFilteredByCountries(){ // name is not documentsFilteredByCountries TODO change documentsByTopicIdForLinkDocumentsToProgramAll
            if(this.documents && this.documents.length > 0 ){
                let docFilByCountries = JSON.parse( JSON.stringify(this.$store.getters.obtainDocumentFilteredByCountry));
                let filteredDocuments = docFilByCountries.filter(filteredDoc => {
                    return !this.documents.some(doc => doc.documentId === filteredDoc.documentId)
                })
                return filteredDocuments
            }else{
                return this.$store.getters.obtainDocumentFilteredByCountry;
            }
            
        },
        documentsByTopicIdForLinkDocumentsToProgram(){
            if(this.documents && this.documents.length > 0 ){
                let docByTopicIdLinkDocProg = JSON.parse(JSON.stringify(this.$store.getters.obtainDocumentsByTopicIdForLinkDocumentsToProgram));
                let filteredDocuments = docByTopicIdLinkDocProg.filter(filteredDoc=> {
                    return !this.documents.some(doc => doc.documentId === filteredDoc.documentId)
                })
                return filteredDocuments
            }
            else{
                return this.$store.getters.obtainDocumentsByTopicIdForLinkDocumentsToProgram;
            }
        },

        scenarios() {
            return this.$store.getters.obtainScenarios;
        },
        documentProgramTopicId() {
            return this.$route.params.programId;
        },
        programStatus() {
            return this.$store.getters.obtainProgramStatus;
        },
        program() {
            return this.$store.getters.obtainProgram;
        },
        companies(){
            let companiesOrder = this.$store.getters.obtainCompanies; 

            companiesOrder.sort(function(a,b){
                if(a.name.toLowerCase() < b.name.toLowerCase()){ return -1}
                if(a.name.toLowerCase() > b.name.toLowerCase()){return 1;}
                return 0;
            });

            return companiesOrder
        },
        countries(){
            const x  = this.$store.getters.obtainCountries; 
            return x.sort((a,b) => a.name.localeCompare(b.name))
        }
    },
    methods: {
        goToHeaderPage(section){
          redirectHelperPage(section)
        },

        // Get response
        async getDocumentApplications() {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainDocumentApplications');
                // toast.success('Document Applications obtained successfully.');
                this.loading = false;
            } catch (error) {
                // toast.error(error.message);
                this.loading = false;
            }
        },
        async getDocumentSources() {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainDocumentSources');
                // toast.success('Document Sources obtained successfully.');
                this.loading = false;
            } catch (error) {
                // toast.error(error.message);
                this.loading = false;
            }
        },
        async getSegmentations() {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainSegmentations', this.selectedTopic.topicId);
                this.loading = false
            } catch (error) {
                this.loading = false;
            }
        },
        async obtainDocumentsByTopicId() {
            try {
                this.loading = true;
                this.loadingDocuments = true;
                await this.$store.dispatch('obtainDocumentsByTopicId', { topicId: this.selectedTopic.topicId, programId: this.program.documentProgramId });
                this.loading = false;
                this.loadingDocuments = false;
            } catch (error) {
                this.loading = false;
            }
        },
        async obtainDocumentsByTopicIdForLinkDocumentsToProgram(){
            try {
                this.loadingDocumentsForLink = true;
                await this.$store.dispatch('obtainDocumentsByTopicIdForLinkDocumentsToProgram', { topicId: this.selectedTopic.topicId});
                this.loadingDocumentsForLink = false;
            } catch (error) {
                this.loadingDocumentsForLink = false;
            }
        },

        // OBTAIN DOCUMENTS FILTERED BY COUNTRY
        async obtainDocumentsByCountry(){
            try {
                this.loadingDocumentsForLink = true;
                await this.$store.dispatch('obtainDocumentsFilteredByCountry', {countryId: null});
                this.loadingDocumentsForLink = false;
            } catch (error) {
                this.loadingDocumentsForLink = false;
            }
        },

        async obtainScenariosByTopicId() {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainScenariosByTopicId', this.selectedTopic.topicId);
                this.loading = false;
            }
            catch (error) {
                this.loading = false;
            }
        },
        async getProgramById() {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainProgramByDocumentProgramTopic', this.documentProgramTopicId);
                this.loading = false;
            }
            catch (error) {
                this.loading = false;
            }
        },
        async getProgramStatus() {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainProgramStatus');
                this.loading = false;
            } catch (error) {
                this.loading = false
            }
        },
        async getCompanies(){
            try {
                this.loading = true;
                await this.$store.dispatch('obtainCompanies');
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        async getCountries() {
            try{
                this.loading = true; 
                await this.$store.dispatch('obtainCountries');
                this.loading = false; 
            }
            catch(error){
                this.loading = false; 
            }
        },

        // sets mf Data
        setProgram() { //set program marketValues  to this sumMarket 
            this.sumMarket = this.program.documentProgramValues;
        },
        getEditForm(newVal) {
            this.newForm.analystNotes = newVal.analystNotes;
            this.newForm.contractValue = newVal.contractValue;
            // we Are not using contractYear
            this.newForm.contractYear = newVal.contractYear;
            this.newForm.countryManufactured = newVal.countryManufactured;
            this.newForm.deliveryYear = newVal.deliveryYear
            this.newForm.name = newVal.documentProgram.name;
            this.newForm.documentProgramStatusId = newVal.documentProgramStatusId;
            this.newForm.manufacturerId = newVal.manufacturerId;
            this.newForm.product = newVal.product;
            this.newForm.programSummary = newVal.programSummary;
            this.newForm.unitPrice = newVal.unitPrice;
            this.newForm.totalQuantity = newVal.totalQuantity;
            this.newForm.programDate = new Date(newVal.programDate);

            // parse Int or string to DateTime for use the calendar 
            this.newForm.programStartYear = (newVal.programStartYear ) ? new Date(newVal.programStartYear,1,1) : null;
            this.newForm.programEndYear = (newVal.programEndYear) ? new Date(newVal.programEndYear,1,1) : null;

            const segmRegion1 = newVal.docProgramTopicSegItems.filter(segItem => segItem.regionCountry)[0]
            if (segmRegion1) {
                this.country = []
                this.country.push(segmRegion1.regionCountry.countryId)
                this.region = []
                this.region.push(segmRegion1.regionCountry.regionId)
            }
            const docTopicSegItems = []
            newVal.docProgramTopicSegItems.map(docProgramTopicSegItem => {
                this.findSegmIndex(docProgramTopicSegItem.segmentationItemId)
                docTopicSegItems.push(docProgramTopicSegItem.docProgramTopicSegItemId)
            })
            this.docTopicSegItemsId = docTopicSegItems
        },
        findSegmIndex(segmentationItemId) {
            const index = this.segmentations.findIndex(segmentation => segmentation.segmentationItems.some(segmentationItem => segmentationItem.segmentationItemId === segmentationItemId))
            this.newForm.segmentationItems[index] = segmentationItemId
        },
        formatDate(date) {
            return formatToGlobalDate(date);
        },

        //Get Names, Methods
        // application (not Used) 
        getApplicationNameById(applications) {
            const result = this.documentApplications
                .filter((d) => applications.includes(d.applicationTypeId))
                .map((d) => {
                    switch (d.name) {
                        case 'Production':
                            d.prefix = 'fa'
                            d.iconName = 'fa-industry'
                            break;
                        case 'Requirements':
                            d.prefix = 'fa'
                            d.iconName = 'fa-landmark-dome'
                            break;
                        case 'Knowledgebase':
                            d.prefix = 'fa'
                            d.iconName = 'fa-book'
                            break
                        case 'Country':
                            d.prefix = 'fa'
                            d.iconName = 'fa-flag'
                            break;
                        case 'Company':
                            d.prefix = 'fa'
                            d.iconName = 'fa-building'
                            break;
                        case 'General':
                            d.prefix = 'fa'
                            d.iconName = 'fa-sun'
                            break;
                        default:
                            d.prefix = 'fa'
                            d.iconName = 'fa-check'
                            break;
                    }
                    return d;
                });
            return result;
        },
        getSourceNameById(sourceId) {
            const result = this.documentSources.find(
                (d) => d.documentSourceId === sourceId
            );
            return result ? result.name : '';
        },
        //for countries
        getCountriesFromSegm(index) {
            let countriesList = []
            this.segmentations.filter(se => se.isRegional)[index].segmentationItems.map(si => si.region.regionCountries.map(rc => countriesList.push(rc.country)))


            countriesList.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            });

            return countriesList.flat()
        },
        setSegmItem(index, event) {
            const segmentationItemId = this.segmentations.filter(se => se.isRegional)[index].segmentationItems.filter(si => si.region.regionCountries.some(c => c.country.countryId === event.value)).map(filtered => filtered.segmentationItemId)
            const regionId = this.segmentations.filter(se => se.isRegional)[index].segmentationItems.filter(si => si.region.regionCountries.some(c => c.country.countryId === event.value)).map(filtered => filtered.region.regionId)
            this.region[index] = regionId[0]
            this.newForm.segmentationItems[index] = segmentationItemId[0]
        },

        // change segm
        getSegmentationsOptions(segmentationItems) {
            let segmentationItemsSort = segmentationItems
            segmentationItemsSort.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            });
            return segmentationItemsSort;
        },
        
        getSegmentationName(segItem, segId) {
            const segName = segItem.filter(item => item.segmentationItemId === segId)
            return segName[0].name
        },
        getCountryName(index, countryId) {
            const countryList = this.getCountriesFromSegm(index)
            const countryName = countryList.filter(item => item.countryId === countryId)
            return countryName[0].name
        },
        openEditDoc(documentId) {
            this.$router.push(`/admin/topics/documents/edit/${documentId}`);
        },

        // EDIT PROGRAM SEGMENTATION

        async editProgramSegm() {  //for edit programOverview Data
            const currentDate = new Date();
            try {
                let programForm = {
                    'name': this.newForm.name,
                    'documentProgramTopics': [],
                    'documentProgramId': this.program.documentProgramId
                }

                let documentProgramTopics = {
                    'analystNotes': this.newForm.analystNotes,
                    'documentProgramTopicId': this.documentProgramTopicId,
                    'topicId': this.program.topicId,
                    'programDate': currentDate,
                    'programSummary': this.newForm.programSummary,
                    'Product': this.newForm.product,
                    //Replace manufacturer to manufacturerId
                    // 'manufacturer': this.newForm.manufacturer,
                    'manufacturerId':this.newForm.manufacturerId, 
                    'CountryManufactured': this.newForm.countryManufactured,
                    //we are not using contractYear
                    // add ProgramStartYear
                    'ContractYear': this.newForm.contractYear,
                    'programStartYear': (this.newForm.programStartYear)? this.newForm.programStartYear.getFullYear() : null,
                    'programEndYear': (this.newForm.programEndYear) ? this.newForm.programEndYear.getFullYear() : null,

                    'ContractValue': (this.newForm.contractValue)?this.newForm.contractValue:null ,
                    'TotalQuantity': this.newForm.totalQuantity,
                    'deliveryYear': this.newForm.deliveryYear,
                    'UnitPrice': (this.newForm.unitPrice)?this.newForm.unitPrice: null ,
                    docProgramTopicSegItems: [],
                    'documentProgramStatusId': this.newForm.documentProgramStatusId,
                    
                }
                let segmentationItems = []
                this.newForm.segmentationItems.map((item, index) => {
                    const regionCountry = {
                        'regionId': this.region[index],
                        'countryId': this.country[index],
                    }
                    if (!this.country[index]) {
                        const segmentationItems1 = {
                            'segmentationItemId': item,
                            'regionCountry': null,
                        }
                        segmentationItems[index] = segmentationItems1
                    }
                    else {
                        const segmentationItems1 = {
                            'segmentationItemId': item,
                            'regionCountry': regionCountry,
                        }
                        segmentationItems[index] = segmentationItems1
                    }
                }
                )
                documentProgramTopics.docProgramTopicSegItems = segmentationItems
                programForm.documentProgramTopics.push(documentProgramTopics)
                await this.$store.dispatch('modifyProgram', programForm);
            } catch (error) {
                console.log(error);
            }
        },


        // Carousel Methods 
        // steps for setting years of documentsfiltered and programfiltered 
        setDocumentsForSetYears() {
            this.minValue = null
            this.maxValue = null
            this.getFirstYearDocumentsMarketValues()
        },
        //get year min max Values 
        getFirstYearDocumentsMarketValues() { //validate all the options of documents and programs
            let arrayDocument = []
            let arrayProgram = []
            let arrayProgramScenarios= []
            
            const currentDate = new Date().getFullYear();
            if (this.documents.length > 0) { // validation if we have document 
                if (this.program.documentProgramValues.length > 0 || this.program.programTopicScenarios.some(pts => pts.programTopicScenarioValues.length> 0 )) {  // validation if we have document or program with scenarios  
                    // for Documents
                    const filteredDocumentsByTopicId = this.documents.map(doc => doc.documentTopics.filter(documentTopic => documentTopic.topicId === this.selectedTopic.topicId))
                    filteredDocumentsByTopicId.map(docMarket => docMarket[0].documentTopicMarketValues.map(mv => arrayDocument.push(mv.year)))
                    // for program
                    this.program.documentProgramValues.map(mv => arrayProgram.push(mv.year))
                    // for scenarios
                    this.program.programTopicScenarios.map(pts => {
                        if(pts.programTopicScenarioValues.length > 0 ){
                            pts.programTopicScenarioValues.map(ptsValues => arrayProgramScenarios.push(ptsValues.year))
                        }} 
                    )

                    // combine arrayProgran && scenarios
                    const programArrayWithScenarios = arrayProgram.concat(arrayProgramScenarios);

                    // combine  arrayDocument && arrayProgram 
                    const documentProgramArray = arrayDocument.concat(programArrayWithScenarios)
                    // get min & max value
                    const docuemntProgramMinValue = this.arrayMin(documentProgramArray)
                    const docuemntProgramMaxValue = this.arrayMax(documentProgramArray)
                    // assign
                    

                    if(docuemntProgramMinValue < currentDate){
                        this.minValue = docuemntProgramMinValue
                    }
                    else{
                        this.minValue = currentDate    
                    }
                    this.maxValue = docuemntProgramMaxValue
                    this.arrayMaxProductOk();
                }
                else { // validation if we have document but not program with scenarios  
                    this.minValue = currentDate
                    this.maxValue = currentDate + 9
                    // // for Documents
                    // const filteredDocumentsByTopicId = this.documents.map(doc => doc.documentTopics.filter(documentTopic => documentTopic.topicId === this.selectedTopic.topicId))
                    // filteredDocumentsByTopicId.map(docMarket => docMarket[0].documentTopicMarketValues.map(mv => arrayDocument.push(mv.year)))
                    // // assign 
                    // const documentMinValue = this.arrayMin(arrayDocument) 
                    // if(documentMinValue < currentDate){
                    //     this.minValue = documentMinValue
                    // }
                    // else{
                    //     this.minValue = currentDate
                    // }
                    // this.maxValue = this.arrayMax(arrayDocument)
                }
            }
            else { // validations if only have program or Scenarios
                if (this.program.documentProgramValues.length > 0 || this.program.programTopicScenarios.some(pts => pts.programTopicScenarioValues.length> 0 )) {
                    // for program
                    this.program.documentProgramValues.map(mv => arrayProgram.push(mv.year))
                    // for scenarios
                    this.program.programTopicScenarios.map(pts => {
                        if(pts.programTopicScenarioValues.length > 0 ){
                            pts.programTopicScenarioValues.map(ptsValues => arrayProgramScenarios.push(ptsValues.year))
                        }} 
                    )
                    // combine arrayProgran && scenarios
                    const programArrayWithScenarios = arrayProgram.concat(arrayProgramScenarios);

                    // assign
                    const programScenarioMinValue = this.arrayMin(programArrayWithScenarios)
                    if(programScenarioMinValue < currentDate){
                        this.minValue = programScenarioMinValue
                    }
                    else{
                        this.minValue = currentDate
                    }
                    this.maxValue = this.arrayMax(programArrayWithScenarios)
                }
                else { // validations if dont have program and documents 
                    this.minValue = currentDate
                    this.maxValue = currentDate + 9
                }
            }
        },
        arrayMin(arr) {
            if (arr.length > 0) {
                return arr.reduce(function (p, v) {
                    return (p < v ? p : v)
                })
            }
        },
        arrayMax(arr) {
            if (arr.length > 0) {
                return arr.reduce(function (p, v) {
                    return (p > v ? p : v)
                })
            }
        },
        arrayMaxProductOk() {
            if (((this.maxValue - 9) - this.minValue) % 10 !== 0) {
                switch (((this.maxValue - 9) - this.minValue) % 10) {
                    case 1:
                        this.maxValue += 9
                        break
                    case 2:
                        this.maxValue += 8
                        break
                    case 3:
                        this.maxValue += 7
                        break
                    case 4:
                        this.maxValue += 6
                        break
                    case 5:
                        this.maxValue += 5
                        break
                    case 6:
                        this.maxValue += 4
                        break
                    case 7:
                        this.maxValue += 3
                        break
                    case 8:
                        this.maxValue += 2
                        break
                    case 9:
                        this.maxValue += 1
                        break
                }
            }
        },
        // for get correct scenario when carousel is mounting 
        getCorrectScenario(scenarioId) {
            try {
                if (this.program.programTopicScenarios) {
                    return this.program.programTopicScenarios.filter(programTopicScenario => programTopicScenario.scenarioId === scenarioId)[0].programTopicScenarioValues
                }
                return null
            } catch (error) {
                return null
            }
        },
        // Carousel parent Function
        sumP() {
            this.position += 1
        },
        addMaxValue(maxVal){
            this.maxValue = maxVal.year
        },
        subP() {
            this.position -= 1
        },
        subMinValue(minVal){
            this.minValue = minVal.year
        },
        // getMarketValue emits props 
        getSummaryMarketValue(array) {
            this.sumMarket = array
        },
        getScenarioMarketValue(array, scenarioIndex) {
            this.scenarioMarket[scenarioIndex] = array.flat()
        },
        // get TopicMarket
        getFilteredTopicMarket(document) { // for documents 
            return document.documentTopics.filter(doc => doc.topicId === this.selectedTopic.topicId)[0].documentTopicMarketValues
        },

        // Copy Program Values To scenario
        openCopyProgramValuesToBaseScenarioModal(){
            this.showPreviewCopyMarketValues = true;
        },

        closePreviewCopyMarketValuesDialog(){
            this.showPreviewCopyMarketValues = false;
        },

        addProgramValuesToBaseScenario(){
            // Remove documentProgramValueId from market values
            let sumMarketCopy = this.sumMarket.flat().map(sm =>{
                    let newObject = { ...sm }
                    if(newObject.documentProgramValueId){
                        delete newObject.documentProgramValueId
                    }
                    return newObject
                } 
            )

            //prepare scenarioBaseMarketValues
            let scenarioBaseMarketPaste = this.scenarioMarket[0].map(sm => {
                let newObject = {...sm};
                return  (newObject.programTopicScenarioValueId) ? newObject  : newObject 
            }).filter(object => object !== null )

            // replace or copy Values
            const tercerArray = sumMarketCopy.map(objeto2 => {
                const objeto1 = scenarioBaseMarketPaste.find(objeto1 => objeto1.year === objeto2.year);
                if(objeto1.value){
                    return (objeto1.programTopicScenarioValueId)
                        ? 
                            //  objeto2 
                            {'unit': objeto1.unit , 'value': objeto1.value, 'year': objeto1.year, 'programTopicScenarioValueId':objeto1.programTopicScenarioValueId  }
                        :
                            {'unit': objeto1.unit , 'value': objeto1.value, 'year': objeto1.year,}
                }else{
                    return objeto2 
                }
            })


            this.scenarioMarket[0] = tercerArray
            this.baseScenarioMarketCopy= tercerArray
            this.isCopyToBaseScenario = true; // for carousel update the changes 
            this.closePreviewCopyMarketValuesDialog();
        },

        replaceProgramValuesToBaseScenario(){
            // Remove documentProgramValueId from market values
            let sumMarketCopy = this.sumMarket.flat().map(sm =>{
                    let newObject = { ...sm }
                    if(newObject.documentProgramValueId){
                        delete newObject.documentProgramValueId
                    }
                    return newObject
                } 
            )

            //prepare scenarioBaseMarketValues
            // let scenarioBaseMarketPaste = this.scenarioMarket[0].map(sm => {
            //     let newObject = {...sm};
            //     return  (newObject.programTopicScenarioValueId) ? newObject  : null 
            // }).filter(object => object !== null )

            // replace or copy Values
            // const tercerArray = sumMarketCopy.map(objeto2 => {
            //     const objeto1 = scenarioBaseMarketPaste.find(objeto1 => objeto1.year === objeto2.year);
            //     if(objeto1.value){
            //         return (objeto1.programTopicScenarioValueId)
            //             ? 
            //                 objeto2 
            //             :
            //                 objeto2
            //     }else{
            //         return objeto2 
            //     }
            // })

            // this.scenarioMarket[0] = tercerArray
            // this.baseScenarioMarketCopy= tercerArray
            this.scenarioMarket[0] = sumMarketCopy
            this.baseScenarioMarketCopy= sumMarketCopy
            this.isCopyToBaseScenario = true; // for carousel update the changes 
            this.closePreviewCopyMarketValuesDialog();
        },

        changeIsCopyToBaseScenario(){
            this.isCopyToBaseScenario = false;
        },

        // SaveProgram
        async saveProgram() { //edit program
            this.postSumProgramIsLoading = true

            let sumMarketValues = this.sumMarket.flat().filter(a => {
                return (a.value !== null && a.unit !== null) && (a.value !== "" && a.unit !== "")
            })

            let form = {
                'documentProgramValues': sumMarketValues,
                'programTopicScenarios': null,
                // 'programTopicUnits':this.sumMarketUnits.flat(),
            }

            // scenarios
            let programTopicScenarios = [
            ]
            this.scenarios.map((scenario, index) => {
                programTopicScenarios[index] = {
                    "scenarioId": scenario.scenarioId,
                }
            })
            this.scenarioMarket.map((scenarioMarket, index) => {
                programTopicScenarios[index] = { ...programTopicScenarios[index], "programTopicScenarioValues": scenarioMarket }
            })
            programTopicScenarios.map((scenario) => {
                scenario.programTopicScenarioValues = scenario.programTopicScenarioValues.filter(
                    a => {
                        return (a.value !== null || a.total !== null) && (a.value !== "" && a.total !== "")
                    }
                )
            }
            )
            form.programTopicScenarios = programTopicScenarios

            await this.editProgramSegm()
            await editProgramOverview(this.documentProgramTopicId, form)
            toast.success('Program Saved!');
            this.postSumProgramIsLoading = false
        },

        async saveProgramGoBack(){
            await this.saveProgram();
            this.goBack();
        },

        // Document List Methods 
        goBack() {
            if(this.fromCreated){
                this.$router.go(-2);
            }
            else{
                this.$router.go(-1);
            }
        },
        // rowExpand Component
        onRowExpand() {
            // console.log('onRowsExpand', event);
        },
        onRowCollapse() {
            // console.log('onRowsCollapse', event);
        },
        expandAll() {
            // this.showExpandedButton 

            this.expandedRows = this.documents.filter(d => d.documentId);
        },
        collapseAll() {
            this.expandedRows = null
        },

        // previewButtons
        openPreviewDocumentDialog(document) {
            this.previewDocumentDialog = true;
            this.selectedRowDocument = document;
        },
        hoverPreviewDocumentDialogIn(document) {
            this.previewHoverDocumentDialog = true;
            this.selectedRowDocument = document;
        },
        hoverPreviewDocumentDialogOut() {
            this.previewHoverDocumentDialog = null;
            this.selectedRowDocument = null;
        },

        openPreviewDocumentContracDialig(document) {
            this.previewDocumentContractDialog = true;
            this.selectedRowDocumentContract = document
        },
        hoverPreviewDocumentContractDialogIn(document) {
            this.previewHoverDocumentContractDialog = true;
            this.selectedRowDocumentContract = document;
        },
        hoverPreviewDocumentContractDialogOut() {
            this.previewHoverDocumentContractDialog = null
        },

        openPreviewDocumentFileDialig(document) {
            this.previewDocumentFileDialog = true;
            this.selectedRowDocumentFile = document
        },

        openCreateDocumentDialog() {
            this.$store.dispatch('cleanSelectedProgram');   // clean SelectedProgram 
            this.$store.dispatch('updateSelectedProgram', this.program.documentProgramId);
            this.$router.push('../../../topics/documents/new')
        },

        openAddDocumentPrevStep(){
            this.addDocumentPrevStep = true;
        },

        openAddDocument(){
            this.addDocument = true;
        },

        async redirectSaveDocument(){
            await this.saveProgram();
            this.openAddDocument();
        },
        
        closeDialog(){
            this.addDocumentPrevStep = false;
            this.addDocument = false; 
            this.linkDocumentDialog = false;
            this.linkDocumentError = null;
            this.unlinkDocumentDialog = false;

            this.unLinkDocumentForm.documentId = null
            this.unLinkDocumentForm.title = null
            this.unLinkDocumentError = null
        },

        // addDocumentCrawler
        // using apifyWebsiteContentCrawler
        async getDocumentDataApifyWebsiteContentCrawler({response}){
            const responseData =  response.data[0]
            const currentDate = new Date();

            const arrayDiff = 9;
            const currentYear = new Date().getFullYear();

            let ara = []; 
            for(let i = 0; i<= arrayDiff; i++){
                const marketValues = {
                    "marketValue":null,
                    "year": currentYear + i ,
                }
                ara.push(marketValues);
            }

            if (this.selectedTopic){
                const newDocument = {
                    title: responseData.title,
                    // documentSourceTypeId: 6,
                    text: responseData.text, 
                    documentDate:  (responseData.date)? responseData.date : currentDate,
                    documentTopics: [
                        {
                            topicId: this.selectedTopic.topicId,
                            program:{
                                documentProgramId: this.program.documentProgramId,
                                // name: this.program.documentProgram.name,
                            },
                            documentProgramId: this.program.documentProgramId,
                            // segmentationItems: segmentationItems
                            documentTopicMarketValues: ara 
                        }
                    ],
                    websiteUrl : responseData.url
                } 
                await this.createDocument(newDocument); 
            }
            else{
                const newDocument = {
                    title: responseData.metadata.title,
                    // documentSourceTypeId: 6,
                    text: responseData.text,
                    documentDate:  (responseData.date)? responseData.date : currentDate,
                    websiteUrl : responseData.url
                }
                await this.createDocument(newDocument); 
            }
            this.closeDialog();
        },

        // using apifySmartArtcvleExtractor
        async getDocumentDataApifySmartArticleExtractor({response}){
            const responseData = response.data[0] 
            const currentDate = new Date();

            const arrayDiff = 9;
            const currentYear = new Date().getFullYear();

            let ara = []; 
            for(let i = 0; i<= arrayDiff; i++){
                const marketValues = {
                    "marketValue":0,
                    "year": currentYear + i ,
                }
                ara.push(marketValues);
            }

            if(this.selectedTopic){
                const newDocument = {
                    title: responseData.title,
                    // documentSourceTypeId: 6,
                    text: responseData.text, 
                    documentDate:  (responseData.date)? responseData.date : currentDate,
                    documentTopics: [
                        {
                            topicId: this.selectedTopic.topicId,
                            program:{
                                documentProgramId: this.program.documentProgramId,
                            },
                            documentProgramId: this.program.documentProgramId,
                            // segmentationItems: segmentationItems
                            documentTopicMarketValues: ara
                        }
                    ],
                    websiteUrl : responseData.url
                } 
                await this.createDocument(newDocument)
            }
            else{
                const newDocument = {
                    title: responseData.title,
                    // documentSourceTypeId: 6,
                    text: responseData.text, 
                    documentDate:  (responseData.date) ? responseData.date : currentDate,
                    websiteUrl : responseData.url
                }
                await this.createDocument(newDocument)
            }
            this.closeDialog();
        },

        // createDocument 
        async createDocument(document){
            try {

                let documentForm = new FormData();

                documentForm.append('title', document.title)
                // documentForm.append('documentSourceTypeId', document.documentSourceTypeId)
                documentForm.append('text', document.text)
                documentForm.append('documentDate', document.documentDate)
                documentForm.append('websiteUrl', document.websiteUrl)

                createFormData(documentForm,'documentTopics' , document.documentTopics)


                const response = await createDocument(documentForm);
                this.openEditDocumentDialog(response.data);
                this.closeDialog();
            } catch (error) {
                console.log(' error', error);
            }
        },

        openEditDocumentDialog(document) {
            this.$router.push(`../../documents/edit/${document.documentId}`);
        },


        // LinksDocumentsMethods
        getCountriesMatchesBydataSet(byTopicId){
            if(byTopicId){
                let documents = this.documentsByTopicIdForLinkDocumentsToProgram
                let countries = [] 
                documents.forEach(document => {
                    if (document.documentCountries) {
                        document.documentCountries.forEach(country => {
                            countries.push(country.country);
                        });
                    }
                }) 
                let uniqueCountries = countries.filter((country, index, self) =>
                    index === self.findIndex((c) => c.countryId === country.countryId)
                );
                
                let sortedCountries = uniqueCountries.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                    return 0;
                });
                this.countriesOptionsForLinkDocumentByTopicId = sortedCountries
            }
            else{
                let documents = this.documentsFilteredByCountries
                let countries = [] 

                documents.forEach(document => {
                    if (document.documentCountries) {
                        document.documentCountries.forEach(country => {
                            countries.push(country.country);
                        });
                    }
                }) 

                let uniqueCountries = countries.filter((country, index, self) =>
                    index === self.findIndex((c) => c.countryId === country.countryId)
                );

                let sortedCountries = uniqueCountries.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                    return 0;
                });

                this.countriesOptionsForLinkDocumentAll = sortedCountries
            }
        },


        setDocumentsForLinkProgram({documentsList}){
            this.documentsLinksProgram = documentsList;
        },

        changeShowAllDocuments({showAllDocBool}){
            this.showAllDocumentsForLinkDocumentsProgram = showAllDocBool
        },
        changeDocumentsFilteredByCountries(){
            if(this.showAllDocumentsForLinkDocumentsProgram){ // allDOcumentsByTopicList
                if(this.documentsFilteredByCountries.length >0){
                    return this.documentsFilteredByCountries
                }
                else{
                    return null
                }
            }
            else{ // related DocumentsList
                return this.documentsByTopicIdForLinkDocumentsToProgram
            }
        },


        async linkDocuments(){
            this.linkDocumentsLoading = true
            try {
                let documentsList = [];
                this.documentsLinksProgram.forEach(dL =>{ 
                    documentsList.push(dL.documentId)
                });
                // // TODO CHANGE LOGIC AFTER RUDYS NEW ENDPOINT
                const response = await linkDocumentsToPrograms( this.program.documentProgramTopicId, documentsList );

                let documentsOptionsList = this.changeDocumentsFilteredByCountries() // get Options 
                let documentFilteredByLinkedDocs = documentsOptionsList.filter(document => documentsList.includes(document.documentId)) // filter by options

                // prevent BUG when change the show AllDocumentsByTopic
                if(documentFilteredByLinkedDocs.length !== documentsList.length){
                    let documentsByTopicIdForLinkDocumentsToProgram = this.documentsFilteredByCountries;
                    documentFilteredByLinkedDocs = null;
                    documentFilteredByLinkedDocs = documentsByTopicIdForLinkDocumentsToProgram.filter(document => documentsList.includes(document.documentId))
                }

                if(response.message === "Successfully created."){
                    this.linkDocumentsLoading = false  

                    // Add To DocumentsVUex new documents
                    this.$store.dispatch('addLinkedDocuments', documentFilteredByLinkedDocs);
                    this.closeDialog();
                }
                else{
                    throw new Error(response.message || "An unknown error occurred while linking documents.");
                }
                
            } catch (error) {
                console.error(error);
                this.linkDocumentError = error
                this.linkDocumentsLoading = false
            }
        },

        openUnlinkDocumentDialog(data){
            this.unLinkDocumentForm.documentId = data.documentId 
            this.unLinkDocumentForm.title = data.title 

            this.unlinkDocumentDialog = true;
        },

        async unLinkDocuments(){
            this.unLinkDocumentsLoading = true 
            try {
                const documentId = this.unLinkDocumentForm.documentId
                const response =  await unlinkDocumetToProgram( documentId, this.program.documentProgramTopicId );
                
                if(response.message === "Successfully deleted."){
                    this.unLinkDocumentsLoading = false

                    //REMOVE TO documentsVuex a document, 
                    this.$store.dispatch('removeLinkedDocument', documentId);
                    // Reload all promises for not bug summary 
                    this.closeDialog();
                }
                else{
                    throw new Error(response.message || "An unknown error occurred while unLinking documents.");
                }

            } catch (error) {
                console.error(error);
                this.unLinkDocumentError = error
                this.unLinkDocumentsLoading = false
            }
        } 

    },
    watch: {
        async program(newVal) {
            if (newVal) {
                await this.getSegmentations() // get segmn 
                await this.obtainScenariosByTopicId() // obtain scenarios 
                this.setProgram()
                this.getEditForm(newVal)// set form of program
                await this.obtainDocumentsByTopicId() // obtain documents

                this.setDocumentsForSetYears() // obtain minYear maxYear

                this.loadingCarousel =false // for Bug of sumMarket
                // the issue was the component was killed for the loading 
                await this.obtainDocumentsByTopicIdForLinkDocumentsToProgram() //GET FILTERED DOCUMENTSBYCOUNTRY FOR LINKDOCUMENT 
                this.getCountriesMatchesBydataSet(true);
                await this.obtainDocumentsByCountry(); //GET FILTERED DOCUMENTSBYCOUNTRY FOR LINKDOCUMENT 
                this.getCountriesMatchesBydataSet(false);
            }
        },
        // async countriesFiltered(newVal){
        //     if (newVal) {
        //         await this.obtainDocumentsByCountry(); //GET FILTERED DOCUMENTSBYCOUNTRY FOR LINKDOCUMENT 
        //     }
        // },

        // 'program.documentProgramValues'(newVal) {
        //     if (newVal) {
        //         this.setProgram() //setProgram MarketValues
        //     }
        // }
    }
}
</script>

<style>
.modalPreview {
    position: fixed;
    z-index: 1101;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    pointer-events: none;
}

.modalContainer {
    height: 50%;
    width: 50%;
    overflow: hidden;
    background-color: white;
    margin: 0.75rem;
    transform: translate3d(0px, 0px, 0px);
    border-radius: 15px;
    padding: 1rem;
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
    /* animation: 0.5s ease-in-out alternate swingViewport ; */
}

@keyframes swingViewport {
    from {
        transform: translateX(-50%);
    }

    to {
        transform: translateX(0);
    }
}

.modalContainer-Text {
    font-size: 1rem;
}

.document-File {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.document-File:hover {
    color: red;
}


.PMC,
.CTU,
.TqD {
    display: flex;
    flex-direction: column;
}


.CTU-contractYear{
    display: flex;
    gap: 1rem;

}

.CTU-contractYear-input{
    /* min-width: 1rem; */
    max-width: 100%;
    position: relative;
}

.CTU-contractYear-input-icon{
    position: absolute;
    right: 2.5%;
}




/* .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
    background: #ececec;
}

.p-datatable .p-datatable-tbody > tr {
    background: #ffffff;
} */
</style>
